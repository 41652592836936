import './all.scss'

import * as _ from 'lodash'
import $ from 'jquery'
import * as bootstrap from 'bootstrap'

$(document).on('content:loaded', function(event) {
  $(event.target).find('[data-bs-toggle="popover"]').each(function(index, el) {
    const popover = new bootstrap.Popover(el)
    $(el).on({
      click: _.method('preventDefault'),
      'shown.bs.popover': _.debounce(() => {
        popover.hide()
      }, 3000)
    })
  })

  $(event.target).find('[data-bs-toggle="tooltip"]').each(function(index, el) {
    new bootstrap.Tooltip(el, {
      boundary: document.body
    })
  })
})
