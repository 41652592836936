export const routes = [
  {
    path: '/',
    /* internal name: '/' */
    /* no component */
    children: [
      {
        path: '/:backend?',
        /* internal name: '//[[backend]]' */
        /* no component */
        children: [
          {
            path: '',
            name: '//[[backend]]/',
            component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/index.vue'),
            /* no children */
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'admins',
            /* internal name: '//[[backend]]/admins' */
            /* no component */
            children: [
              {
                path: '',
                name: '//[[backend]]/admins/',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/admins/index.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: ':admin_id',
                /* internal name: '//[[backend]]/admins/[admin_id]' */
                /* no component */
                children: [
                  {
                    path: 'edit',
                    name: '//[[backend]]/admins/[admin_id]/edit',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/admins/[admin_id]/edit.vue'),
                    /* no children */
                    meta: {
                      "namespace": "backend"
                    }
                  }
                ],
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: 'new',
                name: '//[[backend]]/admins/new',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/admins/new.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              }
            ],
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'areas',
            /* internal name: '//[[backend]]/areas' */
            /* no component */
            children: [
              {
                path: '',
                name: '//[[backend]]/areas/',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/areas/index.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: ':area_id',
                /* internal name: '//[[backend]]/areas/[area_id]' */
                /* no component */
                children: [
                  {
                    path: 'edit',
                    name: '//[[backend]]/areas/[area_id]/edit',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/areas/[area_id]/edit.vue'),
                    /* no children */
                    meta: {
                      "namespace": "backend"
                    }
                  }
                ],
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: 'new',
                name: '//[[backend]]/areas/new',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/areas/new.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              }
            ],
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'employees',
            /* internal name: '//[[backend]]/employees' */
            /* no component */
            children: [
              {
                path: '',
                name: '//[[backend]]/employees/',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/employees/index.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: ':employee_id',
                /* internal name: '//[[backend]]/employees/[employee_id]' */
                /* no component */
                children: [
                  {
                    path: 'edit',
                    name: '//[[backend]]/employees/[employee_id]/edit',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/employees/[employee_id]/edit.vue'),
                    /* no children */
                    meta: {
                      "namespace": "backend"
                    }
                  }
                ],
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: 'new',
                name: '//[[backend]]/employees/new',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/employees/new.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              }
            ],
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'hotels',
            /* internal name: '//[[backend]]/hotels' */
            /* no component */
            children: [
              {
                path: '',
                name: '//[[backend]]/hotels/',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/hotels/index.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: ':hotel_id',
                /* internal name: '//[[backend]]/hotels/[hotel_id]' */
                /* no component */
                children: [
                  {
                    path: 'edit',
                    name: '//[[backend]]/hotels/[hotel_id]/edit',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/hotels/[hotel_id]/edit.vue'),
                    /* no children */
                    meta: {
                      "namespace": "backend"
                    }
                  }
                ],
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: 'new',
                name: '//[[backend]]/hotels/new',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/hotels/new.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              }
            ],
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'login',
            name: '//[[backend]]/login',
            component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/login.vue'),
            /* no children */
            meta: {
              "auth": false,
              "layout": "session",
              "namespace": "backend"
            }
          },
          {
            path: 'orders',
            /* internal name: '//[[backend]]/orders' */
            /* no component */
            children: [
              {
                path: '',
                name: '//[[backend]]/orders/',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/orders/index.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: ':order_id',
                /* internal name: '//[[backend]]/orders/[order_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//[[backend]]/orders/[order_id]/',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/orders/[order_id]/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "backend"
                    }
                  },
                  {
                    path: 'return',
                    name: '//[[backend]]/orders/[order_id]/return',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/orders/[order_id]/return.vue'),
                    /* no children */
                    meta: {
                      "namespace": "backend"
                    }
                  },
                  {
                    path: 'vehicle',
                    name: '//[[backend]]/orders/[order_id]/vehicle',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/orders/[order_id]/vehicle.vue'),
                    /* no children */
                    meta: {
                      "namespace": "backend"
                    }
                  }
                ],
                meta: {
                  "namespace": "backend"
                }
              }
            ],
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'plans',
            /* internal name: '//[[backend]]/plans' */
            /* no component */
            children: [
              {
                path: '',
                name: '//[[backend]]/plans/',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/plans/index.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: ':plan_id',
                /* internal name: '//[[backend]]/plans/[plan_id]' */
                /* no component */
                children: [
                  {
                    path: 'edit',
                    name: '//[[backend]]/plans/[plan_id]/edit',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/plans/[plan_id]/edit.vue'),
                    /* no children */
                    meta: {
                      "namespace": "backend"
                    }
                  }
                ],
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: 'new',
                name: '//[[backend]]/plans/new',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/plans/new.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              }
            ],
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'sellers',
            /* internal name: '//[[backend]]/sellers' */
            /* no component */
            children: [
              {
                path: '',
                name: '//[[backend]]/sellers/',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/sellers/index.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: ':seller_id',
                /* internal name: '//[[backend]]/sellers/[seller_id]' */
                /* no component */
                children: [
                  {
                    path: 'edit',
                    name: '//[[backend]]/sellers/[seller_id]/edit',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/sellers/[seller_id]/edit.vue'),
                    /* no children */
                    meta: {
                      "namespace": "backend"
                    }
                  }
                ],
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: 'new',
                name: '//[[backend]]/sellers/new',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/sellers/new.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              }
            ],
            meta: {
              "namespace": "backend"
            }
          },
          {
            path: 'stores',
            /* internal name: '//[[backend]]/stores' */
            /* no component */
            children: [
              {
                path: '',
                name: '//[[backend]]/stores/',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/index.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: ':store_id',
                /* internal name: '//[[backend]]/stores/[store_id]' */
                /* no component */
                children: [
                  {
                    path: 'cars',
                    /* internal name: '//[[backend]]/stores/[store_id]/cars' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '//[[backend]]/stores/[store_id]/cars/',
                        component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/[store_id]/cars/index.vue'),
                        /* no children */
                        meta: {
                          "namespace": "backend"
                        }
                      },
                      {
                        path: ':car_id',
                        /* internal name: '//[[backend]]/stores/[store_id]/cars/[car_id]' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '//[[backend]]/stores/[store_id]/cars/[car_id]/',
                            component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/[store_id]/cars/[car_id]/index.vue'),
                            /* no children */
                            meta: {
                              "namespace": "backend"
                            }
                          },
                          {
                            path: 'edit',
                            name: '//[[backend]]/stores/[store_id]/cars/[car_id]/edit',
                            component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/[store_id]/cars/[car_id]/edit.vue'),
                            /* no children */
                            meta: {
                              "namespace": "backend"
                            }
                          }
                        ],
                        meta: {
                          "namespace": "backend"
                        }
                      },
                      {
                        path: 'new',
                        name: '//[[backend]]/stores/[store_id]/cars/new',
                        component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/[store_id]/cars/new.vue'),
                        /* no children */
                        meta: {
                          "namespace": "backend"
                        }
                      }
                    ],
                    meta: {
                      "namespace": "backend"
                    }
                  },
                  {
                    path: 'edit',
                    name: '//[[backend]]/stores/[store_id]/edit',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/[store_id]/edit.vue'),
                    /* no children */
                    meta: {
                      "namespace": "backend"
                    }
                  }
                ],
                meta: {
                  "namespace": "backend"
                }
              },
              {
                path: 'new',
                name: '//[[backend]]/stores/new',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/new.vue'),
                /* no children */
                meta: {
                  "namespace": "backend"
                }
              }
            ],
            meta: {
              "namespace": "backend"
            }
          }
        ],
        meta: {
          "namespace": "backend"
        }
      },
      {
        path: '/:frontend?',
        /* internal name: '//[[frontend]]' */
        /* no component */
        children: [
          {
            path: '',
            name: '//[[frontend]]/',
            component: () => import('/home/app/www/releases/20240609030440/app/javascript/frontend/pages/index.vue'),
            /* no children */
            meta: {
              "namespace": "frontend"
            }
          },
          {
            path: 'hotels',
            /* internal name: '//[[frontend]]/hotels' */
            /* no component */
            children: [
              {
                path: ':hotel_id',
                /* internal name: '//[[frontend]]/hotels/[hotel_id]' */
                /* no component */
                children: [
                  {
                    path: 'orders',
                    /* internal name: '//[[frontend]]/hotels/[hotel_id]/orders' */
                    /* no component */
                    children: [
                      {
                        path: 'new',
                        name: '//[[frontend]]/hotels/[hotel_id]/orders/new',
                        component: () => import('/home/app/www/releases/20240609030440/app/javascript/frontend/pages/hotels/[hotel_id]/orders/new.vue'),
                        /* no children */
                        meta: {
                          "namespace": "frontend"
                        }
                      }
                    ],
                    meta: {
                      "namespace": "frontend"
                    }
                  }
                ],
                meta: {
                  "namespace": "frontend"
                }
              }
            ],
            meta: {
              "namespace": "frontend"
            }
          },
          {
            path: 'orders',
            /* internal name: '//[[frontend]]/orders' */
            /* no component */
            children: [
              {
                path: ':order_id',
                /* internal name: '//[[frontend]]/orders/[order_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//[[frontend]]/orders/[order_id]/',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/frontend/pages/orders/[order_id]/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "frontend"
                    }
                  }
                ],
                meta: {
                  "namespace": "frontend"
                }
              }
            ],
            meta: {
              "namespace": "frontend"
            }
          },
          {
            path: 'stores',
            /* internal name: '//[[frontend]]/stores' */
            /* no component */
            children: [
              {
                path: ':store_id',
                /* internal name: '//[[frontend]]/stores/[store_id]' */
                /* no component */
                children: [
                  {
                    path: 'orders',
                    /* internal name: '//[[frontend]]/stores/[store_id]/orders' */
                    /* no component */
                    children: [
                      {
                        path: 'new',
                        name: '//[[frontend]]/stores/[store_id]/orders/new',
                        component: () => import('/home/app/www/releases/20240609030440/app/javascript/frontend/pages/stores/[store_id]/orders/new.vue'),
                        /* no children */
                        meta: {
                          "namespace": "frontend"
                        }
                      }
                    ],
                    meta: {
                      "namespace": "frontend"
                    }
                  }
                ],
                meta: {
                  "namespace": "frontend"
                }
              }
            ],
            meta: {
              "namespace": "frontend"
            }
          }
        ],
        meta: {
          "namespace": "frontend"
        }
      },
      {
        path: '/:store?',
        /* internal name: '//[[store]]' */
        /* no component */
        children: [
          {
            path: '',
            name: '//[[store]]/',
            component: () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/index.vue'),
            /* no children */
            meta: {
              "namespace": "store"
            }
          },
          {
            path: 'cars',
            /* internal name: '//[[store]]/cars' */
            /* no component */
            children: [
              {
                path: '',
                name: '//[[store]]/cars/',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/cars/index.vue'),
                /* no children */
                meta: {
                  "namespace": "store"
                }
              },
              {
                path: ':car_id',
                /* internal name: '//[[store]]/cars/[car_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//[[store]]/cars/[car_id]/',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/cars/[car_id]/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "store"
                    }
                  },
                  {
                    path: 'edit',
                    name: '//[[store]]/cars/[car_id]/edit',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/cars/[car_id]/edit.vue'),
                    /* no children */
                    meta: {
                      "namespace": "store"
                    }
                  }
                ],
                meta: {
                  "namespace": "store"
                }
              },
              {
                path: 'new',
                name: '//[[store]]/cars/new',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/cars/new.vue'),
                /* no children */
                meta: {
                  "namespace": "store"
                }
              }
            ],
            meta: {
              "namespace": "store"
            }
          },
          {
            path: 'login',
            name: '//[[store]]/login',
            component: () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/login.vue'),
            /* no children */
            meta: {
              "auth": false,
              "layout": "session",
              "namespace": "store"
            }
          },
          {
            path: 'orders',
            /* internal name: '//[[store]]/orders' */
            /* no component */
            children: [
              {
                path: '',
                name: '//[[store]]/orders/',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/orders/index.vue'),
                /* no children */
                meta: {
                  "namespace": "store"
                }
              },
              {
                path: ':order_id',
                /* internal name: '//[[store]]/orders/[order_id]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//[[store]]/orders/[order_id]/',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/orders/[order_id]/index.vue'),
                    /* no children */
                    meta: {
                      "namespace": "store"
                    }
                  },
                  {
                    path: 'return',
                    name: '//[[store]]/orders/[order_id]/return',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/orders/[order_id]/return.vue'),
                    /* no children */
                    meta: {
                      "namespace": "store"
                    }
                  },
                  {
                    path: 'vehicle',
                    name: '//[[store]]/orders/[order_id]/vehicle',
                    component: () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/orders/[order_id]/vehicle.vue'),
                    /* no children */
                    meta: {
                      "namespace": "store"
                    }
                  }
                ],
                meta: {
                  "namespace": "store"
                }
              }
            ],
            meta: {
              "namespace": "store"
            }
          },
          {
            path: 'stores',
            /* internal name: '//[[store]]/stores' */
            /* no component */
            children: [
              {
                path: 'edit',
                name: '//[[store]]/stores/edit',
                component: () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/stores/edit.vue'),
                /* no children */
                meta: {
                  "namespace": "store"
                }
              }
            ],
            meta: {
              "namespace": "store"
            }
          }
        ],
        meta: {
          "namespace": "store"
        }
      }
    ],
    meta: {
      "namespace": "frontend"
    }
  }
]
