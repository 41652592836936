


export const layouts = {
'backend/App': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/App.vue'),
'components/ActionerAlert': () => import('/home/app/www/releases/20240609030440/app/javascript/components/ActionerAlert.vue'),
'components/BlankModal': () => import('/home/app/www/releases/20240609030440/app/javascript/components/BlankModal.vue'),
'components/ExportationDialog': () => import('/home/app/www/releases/20240609030440/app/javascript/components/ExportationDialog.vue'),
'components/FormErrorAlert': () => import('/home/app/www/releases/20240609030440/app/javascript/components/FormErrorAlert.vue'),
'components/ImagePreviewDialog': () => import('/home/app/www/releases/20240609030440/app/javascript/components/ImagePreviewDialog.vue'),
'components/LinkDestroy': () => import('/home/app/www/releases/20240609030440/app/javascript/components/LinkDestroy.vue'),
'components/LinkEdit': () => import('/home/app/www/releases/20240609030440/app/javascript/components/LinkEdit.vue'),
'components/LinkShow': () => import('/home/app/www/releases/20240609030440/app/javascript/components/LinkShow.vue'),
'components/PaginationBar': () => import('/home/app/www/releases/20240609030440/app/javascript/components/PaginationBar.vue'),
'frontend/App': () => import('/home/app/www/releases/20240609030440/app/javascript/frontend/App.vue'),
'store/App': () => import('/home/app/www/releases/20240609030440/app/javascript/store/App.vue'),
'backend/components/Footer': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/components/Footer.vue'),
'backend/components/Navbar': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/components/Navbar.vue'),
'backend/layouts/default': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/layouts/default.vue'),
'backend/pages/index': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/index.vue'),
'backend/pages/login': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/login.vue'),
'frontend/layouts/default': () => import('/home/app/www/releases/20240609030440/app/javascript/frontend/layouts/default.vue'),
'frontend/pages/index': () => import('/home/app/www/releases/20240609030440/app/javascript/frontend/pages/index.vue'),
'store/components/Footer': () => import('/home/app/www/releases/20240609030440/app/javascript/store/components/Footer.vue'),
'store/components/Navbar': () => import('/home/app/www/releases/20240609030440/app/javascript/store/components/Navbar.vue'),
'store/layouts/default': () => import('/home/app/www/releases/20240609030440/app/javascript/store/layouts/default.vue'),
'store/pages/index': () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/index.vue'),
'store/pages/login': () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/login.vue'),
'backend/pages/admins/Fields': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/admins/Fields.vue'),
'backend/pages/admins/index': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/admins/index.vue'),
'backend/pages/admins/new': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/admins/new.vue'),
'backend/pages/areas/Fields': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/areas/Fields.vue'),
'backend/pages/areas/index': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/areas/index.vue'),
'backend/pages/areas/new': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/areas/new.vue'),
'backend/pages/employees/Fields': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/employees/Fields.vue'),
'backend/pages/employees/index': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/employees/index.vue'),
'backend/pages/employees/new': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/employees/new.vue'),
'backend/pages/hotels/Fields': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/hotels/Fields.vue'),
'backend/pages/hotels/index': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/hotels/index.vue'),
'backend/pages/hotels/new': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/hotels/new.vue'),
'backend/pages/orders/index': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/orders/index.vue'),
'backend/pages/plans/Fields': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/plans/Fields.vue'),
'backend/pages/plans/index': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/plans/index.vue'),
'backend/pages/plans/new': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/plans/new.vue'),
'backend/pages/stores/Fields': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/Fields.vue'),
'backend/pages/stores/index': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/index.vue'),
'backend/pages/stores/new': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/new.vue'),
'backend/pages/sellers/Fields': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/sellers/Fields.vue'),
'backend/pages/sellers/index': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/sellers/index.vue'),
'backend/pages/sellers/new': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/sellers/new.vue'),
'components/simple_form/controls/DropdownItem': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/DropdownItem.vue'),
'components/simple_form/controls/checkboxes': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/checkboxes.vue'),
'components/simple_form/controls/date': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/date.vue'),
'components/simple_form/controls/datetime': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/datetime.vue'),
'components/simple_form/controls/file': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/file.vue'),
'components/simple_form/controls/lazy_dropdown': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/lazy_dropdown.vue'),
'components/simple_form/controls/markdown': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/markdown.vue'),
'components/simple_form/controls/number': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/number.vue'),
'components/simple_form/controls/plaintext': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/plaintext.vue'),
'components/simple_form/controls/radios': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/radios.vue'),
'components/simple_form/controls/select': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/select.vue'),
'components/simple_form/controls/selectize': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/selectize.vue'),
'components/simple_form/controls/string': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/string.vue'),
'components/simple_form/controls/text': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/text.vue'),
'components/simple_form/controls/transfer': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/controls/transfer.vue'),
'components/simple_form/layouts/HorizontalGroup': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/layouts/HorizontalGroup.vue'),
'components/simple_form/layouts/InlineGroup': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/layouts/InlineGroup.vue'),
'components/simple_form/layouts/VerticalGroup': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/layouts/VerticalGroup.vue'),
'components/simple_form/layouts/control_only': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/layouts/control_only.vue'),
'components/simple_form/layouts/form_horizontal': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/layouts/form_horizontal.vue'),
'components/simple_form/layouts/form_inline': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/layouts/form_inline.vue'),
'components/simple_form/layouts/form_vertical': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/layouts/form_vertical.vue'),
'components/simple_form/layouts/group': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/layouts/group.vue'),
'components/simple_form/layouts/submit': () => import('/home/app/www/releases/20240609030440/app/javascript/components/simple_form/layouts/submit.vue'),
'store/pages/cars/Fields': () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/cars/Fields.vue'),
'store/pages/cars/index': () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/cars/index.vue'),
'store/pages/cars/new': () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/cars/new.vue'),
'store/pages/stores/Fields': () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/stores/Fields.vue'),
'store/pages/stores/edit': () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/stores/edit.vue'),
'store/pages/orders/index': () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/orders/index.vue'),
'backend/pages/admins/[admin_id]/edit': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/admins/[admin_id]/edit.vue'),
'backend/pages/areas/[area_id]/edit': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/areas/[area_id]/edit.vue'),
'backend/pages/employees/[employee_id]/edit': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/employees/[employee_id]/edit.vue'),
'backend/pages/hotels/[hotel_id]/edit': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/hotels/[hotel_id]/edit.vue'),
'backend/pages/orders/[order_id]/index': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/orders/[order_id]/index.vue'),
'backend/pages/orders/[order_id]/return': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/orders/[order_id]/return.vue'),
'backend/pages/orders/[order_id]/vehicle': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/orders/[order_id]/vehicle.vue'),
'backend/pages/plans/[plan_id]/edit': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/plans/[plan_id]/edit.vue'),
'backend/pages/stores/[store_id]/edit': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/[store_id]/edit.vue'),
'backend/pages/sellers/[seller_id]/edit': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/sellers/[seller_id]/edit.vue'),
'frontend/pages/orders/[order_id]/index': () => import('/home/app/www/releases/20240609030440/app/javascript/frontend/pages/orders/[order_id]/index.vue'),
'store/pages/cars/[car_id]/edit': () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/cars/[car_id]/edit.vue'),
'store/pages/cars/[car_id]/index': () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/cars/[car_id]/index.vue'),
'store/pages/orders/[order_id]/index': () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/orders/[order_id]/index.vue'),
'store/pages/orders/[order_id]/return': () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/orders/[order_id]/return.vue'),
'store/pages/orders/[order_id]/vehicle': () => import('/home/app/www/releases/20240609030440/app/javascript/store/pages/orders/[order_id]/vehicle.vue'),
'backend/pages/stores/[store_id]/cars/Fields': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/[store_id]/cars/Fields.vue'),
'backend/pages/stores/[store_id]/cars/index': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/[store_id]/cars/index.vue'),
'backend/pages/stores/[store_id]/cars/new': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/[store_id]/cars/new.vue'),
'frontend/pages/hotels/[hotel_id]/orders/new': () => import('/home/app/www/releases/20240609030440/app/javascript/frontend/pages/hotels/[hotel_id]/orders/new.vue'),
'frontend/pages/stores/[store_id]/orders/new': () => import('/home/app/www/releases/20240609030440/app/javascript/frontend/pages/stores/[store_id]/orders/new.vue'),
'backend/pages/stores/[store_id]/cars/[car_id]/edit': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/[store_id]/cars/[car_id]/edit.vue'),
'backend/pages/stores/[store_id]/cars/[car_id]/index': () => import('/home/app/www/releases/20240609030440/app/javascript/backend/pages/stores/[store_id]/cars/[car_id]/index.vue'),
}
export const createGetRoutes = (router, withLayout = false) => {
  const routes = router.getRoutes()
  if (withLayout) {
      return routes
  }
  return () => routes.filter(route => !route.meta.isLayout)
}

export function setupLayouts(routes) {
  function deepSetupLayout(routes, top = true) {
    return routes.map(route => {
      if (route.children?.length > 0) {
        route.children = deepSetupLayout(route.children, false)
      }
      
      if (top) {
        // unplugin-vue-router adds a top-level route to the routing group, which we should skip.
        const skipLayout = !route.component && route.children?.find(r => (r.path === '' || r.path === '/') && r.meta?.isLayout)  

        if (skipLayout) {
          return route
        }

        if (route.meta?.layout !== false) {
          return { 
            path: route.path,
            component: layouts[route.meta?.layout || 'default'],
            children: route.path === '/' ? [route] : [{...route, path: ''}],
            meta: {
              isLayout: true
            }
          }
        }
      }

      if (route.meta?.layout) {
        return { 
          path: route.path,
          component: layouts[route.meta?.layout],
          children: [ {...route, path: ''} ],
          meta: {
            isLayout: true
          }
        }
      }

      return route
    })
  }

    return deepSetupLayout(routes)

}
